import React, { useState, useContext,useCallback } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import bG from "assets/img/Wassiya_sombre2.jpg";
import { makeStyles } from '@material-ui/core/styles';
//Firebase
import {Redirect, withRouter } from "react-router-dom";
import { AuthContext } from "../../login/Auth.js";
import app from "../../login/base.js";
//icons
import EmailIcon from '@material-ui/icons/Email';
import AddAlert from '@material-ui/icons/AddAlert'
//pop up
import Popup from './Popup'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',

    [theme.breakpoints.down("sm")]: {
      height: '100vh',
     }
  },
  imageContainer: {
    height: '40%',
    backgroundImage:  `url(${bG})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down("sm")]: {
      height: '25%',
      backgroundSize: 'cover',
     }
  },
  paperContainer: {
    height: '60%',
    [theme.breakpoints.down("sm")]: {
      height: '75%',
     }
  },
  image: {
    backgroundImage:  `url(${bG})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(10, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(4, 3),
     }
  },

  cardCategory: {
        color: "#f1f1f2",
        margin: "0",
        fontSize: "24px",
        fontWeight:"400",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "0",
        [theme.breakpoints.down("sm")]: {
         fontSize: "18px",
         marginTop: "0",
         paddingTop: "0px",
     },
 },
     cardDescription: {
           color: "#333",
           margin: "0",
           fontSize: "18px",
           fontWeight:"400",
           marginTop: "2%",
           paddingTop: "10px",
           marginBottom: "5%",
           [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            marginTop: "5%",
            paddingTop: "0",
        }
    },



}));
const SignUp = ({ history }) => {
const [button,setButton] = React.useState({
    message:"Envoyer e-mail de verification",
    color:""
});
const { currentUser } = useContext(AuthContext);
const [open, setOpen] = React.useState(false);
const classes = useStyles();


const handleClick= ()=>{
    if (!button.color && !currentUser.emailVerified){
    try {
setButton({ ...button, color : "success" ,message : "J'ai valider mon adresse e-mail "});
    currentUser.sendEmailVerification().then(function() {
        setOpen(true);
        setTimeout(function() {
          setOpen(false);
      }, 2000);
    }).catch(function(error) {
        alert(currentUser);
    });
} catch (error) {
}

}else {
    history.push("/monespace");
}
};

if (currentUser==null) {
         return <Redirect to="/signIn" />;
    }
return(



<Grid container component="main" className={classes.root}>
    <Grid container component="main" className={classes.imageContainer}>
    </Grid >
    <Grid container component="main" className={classes.paperContainer}>
        <Grid item xs component={Paper} elevation={6} square>
        <div className={classes.paper}>
        <Card>
        <CardHeader color="success" >
            <p className={classes.cardCategory}>Votre demande d'inscription a bien été enregistrer </p>

        </CardHeader>
        <CardBody className={classes.bodyCard}>

        <p className={classes.cardDescription}>
        Pour pouvoir utiliser Al Wassiya , vous devez faire valider l'adresse e-mail de votre compte.
        Vous allez recevoir un email pour valider votre inscription
        <b> cliquer sur le lien dans l'e-mail que nous vous avons envoyé . </b> <br/>

        Si vous avez déjà eu l'occasion de cliquer sur le lien de l'e-mail que nous vous avons envoyé
        vous n'avez rien à faire : votre adresse e-mail a déjà été validée.
        </p>

        <div style={{textAlign:"right", marginTop:"2%"}}>
        <Button color={button.color ? 'success' : 'info'} onClick={handleClick} endIcon={<EmailIcon/>} >
         {button.message}
        </Button>
        </div>
        </CardBody>
        </Card>
        {button.color  && <Popup/>}


        </div>
        </Grid>
    </Grid>
    <Snackbar
      place="tc"
      open={open}
      autoHideDuration={1500}
      icon={AddAlert}
      message={<p style={{textAlign:"center",display:"inline-block",fontSize:"18px"}}>Le mail de vérification vous a bien été envoyé .</p>}
      color="info"
      closeNotification={() => setOpen(false)}

      close
     />
</Grid>

);
}
export default withRouter(SignUp);
