// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var docs = [
  'Envoyer mes dernieres volontés',
  "Envoyer le récapitulafif de mes dettes",
  "Envoyer mon inventaire",
  "Envoyer les fichier que j'ai téléchargés"
];
let profil = {
 firstName:"Badr",
 lastName: "Hannach",
 address:"22 rue de torcy",
 city:"Chelles",
 country:"France",
 postalCode:"77500",
 email:"Hannach.badr@gmail.com",
 civility:"M",
 phone:"0781804646",


};
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  docs,
  profil,
  server
};
