import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import {profil} from "variables/general.js"
import avatar from "assets/img/faces/marc.jpg";
//icons
import Person from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import List from "@material-ui/icons/ListAlt";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
},
cardCategory: {
  color: "rgba(0,0,0,.62)",
  margin: "0",
  fontSize: "14px",
  marginTop: "10",
  marginBottom: "0"
},
cardTitle: {
  color: "#777",
  minHeight: "auto",
  fontWeight: "500",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  paddingTop: "20px",
  textDecoration: "none"
},
  selectDisplay: {

    marginTop: "43px",
    width:"100 px",
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const [value, setValue] = React.useState('Controlled');
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader color="warning">


              <h4 className={classes.cardTitleWhite}>Editer le profil</h4>
              <p className={classes.cardCategoryWhite}>Complétez votre profil</p>
            </CardHeader>
            <CardBody>
            {UserPresentation(false)}
            </CardBody>
            <CardFooter>
              <Button color="warning">Confirmer</Button>
            </CardFooter>
          </Card>
        </GridItem>
        {/*<GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
              <h4 className={classes.cardTitle}>Alec Thompson</h4>
              <p className={classes.description}>
                Don{"'"}t be scared of the truth because we need to restart the
                human foundation in truth And I love you like Kanye loves Kanye
                I love Rick Owens’ bed design but the back is...
              </p>
              <Button color="primary" round>
                Follow
              </Button>
            </CardBody>
          </Card>
        </GridItem>*/}
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={5}>
        <Card>
          <CardHeader color="success" stats icon>
          <CardIcon color="success"><PhoneIcon/></CardIcon>
            <h4 className={classes.cardTitle}>Coordonnées</h4>
            <p className={classes.cardCategory}></p>
          </CardHeader>
          <CardBody>
          {UserCoordonnees(false)}
          </CardBody>
          <CardFooter>
            <Button color="success">Confirmer</Button>
          </CardFooter>
        </Card>
      </GridItem>
      </GridContainer>
    </div>
  );
}
export function UserPresentation(disable) {
    const classes = useStyles();
    return(
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

                  <GridContainer>
                      <GridItem xs={1} sm={1} md={1}>
                        <Select
                            className={classes.selectDisplay}
                            labelText="test"
                            id="civility"
                            disabled={disable}
                            //value={age}
                            //onChange={handleChange}
                            value={profil.civility}
                          >
                                <MenuItem value={"M"}>M.</MenuItem>
                                <MenuItem value={"Mme"}>Mme.</MenuItem>
                                <MenuItem value={"Mlle"}>Mlle.</MenuItem>
                        </Select>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Prénom"
                        id="first-name"
                        value={profil.firstName}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Nom"
                        id="last-name"
                        value={profil.lastName}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Adresse"
                        id="address"
                        value={profil.address}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Ville"
                        id="city"
                        value={profil.city}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                    </GridContainer>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Pays"
                        id="country"
                        value={profil.country}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Code postal"
                        id="postal-code"
                        value={profil.postalCode}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                  </GridContainer>
            </GridItem>
            </GridContainer>
            </div>
 );
}
export function UserCoordonnees(disable) {
    const classes = useStyles();
    return(
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                      <CustomInput
                        labelText="Adresse e-mail"
                        id="email-address"
                        value={profil.email}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Téléphone"
                        id="phone"
                        value={profil.phone}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                  </GridContainer>
            </GridItem>
            </GridContainer>
            </div>
 );
}
export function UserShortPresentation(disable) {
    const classes = useStyles();
    return(
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>

                  <GridContainer>
                    <GridItem xs={12} sm={6} md={4}>
                      <CustomInput
                        labelText="Prénom"
                        id="first-name"
                        value={profil.firstName}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4}>
                      <CustomInput
                        labelText="Nom"
                        id="last-name"
                        value={profil.lastName}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                      disabled: disable
                    }}
                      />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Adresse e-mail"
                          id="email-address"
                          value={profil.email}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                        disabled: disable
                      }}
                        />
                        </GridItem>
                  </GridContainer>
            </GridItem>
            </GridContainer>
            </div>
 );
}
