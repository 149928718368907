import React, { useState, useContext,useCallback } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import bG from "assets/img/Tampon.jpg";
import { makeStyles } from '@material-ui/core/styles';
//Firebase
import {Redirect, withRouter } from "react-router-dom";
import { AuthContext } from "../../login/Auth.js";
import app from "../../login/base.js";
//icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';



function Copyright() {
  return (
    <Typography variant="body2" color="primary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.Alwassiya.com/">
        Al Wassiya
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    [theme.breakpoints.down("sm")]: {
      height: '100vh',
     }
  },
  image: {
    backgroundImage:  `url(${bG})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(20, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(8, 4),
     }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#f00",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),

  },
  forgetPassWord: {

    textAlign:"center",
  },
}));



const SignInSide = ({ history }) => {


  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);

  const [formErrors, setFormErrors] = React.useState({


    password: "",
    email:"",
    emailControl:false,
    passwordControl:false,

  });
  const [values, setValues] = React.useState({
    email:'',
    password: '',
    showPassword: false,
  });

  const handleChange = prop => event => {
     setValues({ ...values, [prop]: event.target.value });
   };

   const handleClickShowPassword = () => {
     setValues({ ...values, showPassword: !values.showPassword });
   };

   const handleMouseDownPassword = event => {
     event.preventDefault();
   };

   const handleSignIn = event =>
    {
       if(controlField()){
           try {
              app
               .auth()
               .signInWithEmailAndPassword(values.email,values.password);
             history.push("/monespace");
         } catch (error) {  event.preventDefault();
             alert(error);
           }
       }
    };

    const handleSignUp = event =>
     {
        if(controlField()){
            try {
               app
                .auth()
                .createUserWithEmailAndPassword(values.email,values.password);
                app
                 .auth()
                 .signInWithEmailAndPassword(values.email,values.password);

                currentUser.sendEmailVerification().then(function() {
                  //history.push("/Signup");
                  alert(JSON.stringify(currentUser));
                }).catch(function(error) {
                    alert(error);
                });
            } catch (error) {
            }

        }
     };


   function controlField(){

    let value ;
    let valid,emailControlField, passwordControlField;
        value=values.email;
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if (!valid) { emailControlField = 'Veuillez entrer une adresse e-mail valide'}
        else {emailControlField= ''}

        value = values.password;
        valid = value.length >= 8;
        if (!valid) { passwordControlField = 'Veuillez entrer un mot de passe valide'}
        else {passwordControlField= ''}
        setFormErrors({ ...formErrors, passwordControl : passwordControlField ,emailControl : emailControlField});
        if (emailControlField !== '' ||passwordControlField !== '' ){
            return false;
        }else {
            return true;
        }
   };

  if (currentUser!==null) {
      if (!currentUser.emailVerified) {
           return <Redirect to="/signUp" />;
      } else {
          return <Redirect to="/monespace" />;
      }

   }
  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <hr/>
          <div>
            <TextField
              error={formErrors.emailControl}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse E-mail "
              name="email"
              helperText={formErrors.emailControl}
              autoComplete="email"
              autoFocus
              value={values.email}
              onChange={handleChange('email')}

            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe "
              id="password"
              autoComplete="current-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              error={formErrors.passwordControl}
              helperText={formErrors.passwordControl}
              InputProps={{
                 endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
            }}
            />
            <br/>
            <Grid container spacing={2}>
                <Grid item xs>

                        <Button
                          onClick={handleSignIn}
                          fullWidth
                          variant="contained"
                           color="secondary"
                          className={classes.submit}
                        >
                          Se connecter
                        </Button>
                </Grid>
                <Grid item xs>

                        <Button
                          onClick={handleSignUp}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          S'inscrire
                        </Button>

                </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
              <br/>
              <div className={classes.forgetPassWord} >
                <Link href="#" variant="body2">
                  Mot de passe oublié ?
                </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div >
        <Box mt={5}>
          <Copyright />
        </Box>
        </div>
      </Grid>
    </Grid>
  );
}
export default withRouter(SignInSide);
