import React, { useState, useContext,useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import bG from "assets/img/Wassiya_sombre2.jpg";
//firebase
import { AuthContext } from "../../login/Auth.js";
import app from "../../login/base.js";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    height: '30%',
    width:"100%",
    [theme.breakpoints.down("sm")]: {
      height: '50%',
     }
  },
  paperContainer: {
    height: '100%',
    [theme.breakpoints.down("sm")]: {
      height: '70%',
     }
  },
  paper: {
    marginRight:"2%",
    marginLeft:"2%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0),
     }
  },

  cardCategory: {
        color: "#777",
        fontSize: "18px",
        fontWeight:"400",
        padding: "10px",
        marginBottom: "0",
        textAlign:"center",
        [theme.breakpoints.down("sm")]: {
         fontSize: "14px",
         paddingTop: "0px",
     },
  },
     cardDescription: {
           color: "#333",
           fontSize: "14px",
           fontWeight:"400",
           paddingLeft: "2%",
           paddingTop: "10px",
           [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
            marginTop: "2%",
            paddingTop: "0",
        },


}}));

export default function SimplePopover() {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [values, setValues] = React.useState({
    email:currentUser.email,
  });

  const setEmail =prop=>event=>{
      currentUser.updateEmail(values.email).then(function() {
         console.log("email :"+values.email);
  // Update successful.
}).catch(function(error) {
  // An error happened.
  console.log("email :"+values.email);
});
  }

  const handleChange = prop => event => {
     setValues({ ...values, [prop]: event.target.value });
   };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
    <Button aria-describedby={id} color="primary" onClick={handleClick}  >
     Je n'ai pas reçu d'e-mail de verification
    </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
      <Grid container component="main" className={classes.root} alignItems="center">
          <Grid container component="Paper" className={classes.paperContainer}>
           <Grid item xs={12} component={Paper} elevation={6} square>
            <p className={classes.cardCategory}>Vous n'avez pas reçu d'e-mail ?</p>

              <p className={classes.cardDescription}>
              Nous vous invitons à:
               <li>Vérifier le dossier spams dans votre messagerie</li>
               <li>Vérifier que l'adresse e-mail fournit soit la bonne</li>
               <li>si celà ne fonctionne n'hesitez pas à nous contacter</li>
              </p>

              <div style={{textAlign:"center", margin:"3%"}}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse E-mail "
                name="email"
                autoComplete="email"
                autoFocus
                value={values.email}
                onChange={handleChange('email')}

              />
              <Button color="primary" variant="contained" onClick={setEmail('email')}  >
               Modifier adresse e-mail
              </Button>

              </div>
              </Grid>
              </Grid>
          </Grid>



      </Popover>
    </div>
  );
}
