import React, { useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./login/Auth";
import PrivateRoute from "./login/PrivateRoute";
// core components
import Admin from "layouts/Admin.js";
import SignIn from "layouts/SignIn.js";
import SignUp from "views/Signup/SignUp.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();
const App = () => {
  return (

      <AuthProvider>
       <Router>
         <div>
           <PrivateRoute  path="/monespace" component={Admin} />
           <Route  path="/signIn" component={SignIn} />
           <Route exact path="/signUp" component={SignUp} />

         </div>
       </Router>
     </AuthProvider>

 );}


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
