import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Infos from "@material-ui/icons/InfoOutlined";
import Person from "@material-ui/icons/Person";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import SendSetting from "components/SendSetting/Sendsetting.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";

import { docs, profil, server } from "variables/general.js";
import {UserShortPresentation} from "views/UserProfile/UserProfile.js"
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  return (

    <div>
    <div>
    <Card>
      <CardHeader color="warning" stats icon>
          <CardIcon color="warning">
          <Icon>person</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>Informations personnelles</p>
          <h3 className={classes.cardTitle}>Mon profil</h3>
      </CardHeader>
      <CardBody className={classes.bodyCard}>
      {UserShortPresentation(true)}
      <div style={{textAlign:"right", marginTop:"2%"}}>
      <Button color="warning" href="./user" >
        Modifier mes informations
      </Button>
      </div>
      </CardBody>
    </Card>
    </div>

    <p className={classes.sectionTitle}>Mes documents </p> <hr/><br/>
      <GridContainer >
        <GridItem xs={12} sm={6} md={4}>
          <Card>
              <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                  <Icon>content_copy</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Modifié le : 25/12/18</p>
                  <h3 className={classes.cardTitle}>
                  Mes volontés
                  </h3>
              </CardHeader>
              <CardBody className={classes.bodyCard}>
              <hr className={classes.hrDescription} />
              <p className={classes.cardDescription}>
               <br/>Exprimez vos volontés et désignez les personnes en charge de les faire respecter . </p><br/>
               <Button color="danger">
                 Commencer
               </Button>
              </CardBody>
              <CardFooter stats>
                  <div className={classes.stats}>
                  <Infos>
                  </Infos>
                  <a href="https:/alwassiya.com/mes-dernieres-volontes/" target="_blank">
                  Plus d'informations
                  </a>
                  </div>
              </CardFooter>
          </Card>
        </GridItem>

          <GridItem xs={12} sm={6} md={4}>
              <Card>
                  <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                      <Icon>receipt</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Dettes en cours : 6</p>
                      <h3 className={classes.cardTitle}>Mes dettes</h3>
                  </CardHeader>
                  <CardBody className={classes.bodyCard}>
                      <hr className={classes.hrDescription} />
                      <p className={classes.cardDescription}>
                      <br/>Inventoriez vos biens à ne pas oublier au moment de votre succession, avec ou sans testament.</p><br/>
                      <Button color="info">
                        Commencer
                      </Button>
                  </CardBody>
                  <CardFooter stats>
                      <div className={classes.stats}>
                      <Infos>
                      </Infos>
                      <a href="https:/alwassiya.com/mes-dernieres-volontes/" target="_blank">
                      Plus d'informations
                      </a>
                      </div>
                  </CardFooter>
              </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
                <Card>
                  <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                      <Icon>list_alt</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Modifié le :25/12/18</p>
                      <h3 className={classes.cardTitle}>Mon inventaire</h3>
                  </CardHeader>
                  <CardBody className={classes.bodyCard}>
                  <hr className={classes.hrDescription} />
                  <p className={classes.cardDescription}>
                   <br/>Inventoriez vos biens à ne pas oublier au moment de votre succession, avec ou sans testament.</p><br/>
                   <Button color="success">
                     Commencer
                   </Button>
                  </CardBody>
                  <CardFooter stats>
                      <div className={classes.stats}>
                      <Infos>
                      </Infos>
                      <a href="https:/alwassiya.com/mes-dernieres-volontes/" target="_blank">
                      Plus d'informations
                      </a>
                      </div>
                  </CardFooter>
                </Card>
          </GridItem>
      </GridContainer>
      <div>
      <p className={classes.sectionTitle}>Mes contacts </p> <hr/><br/>
      <CustomTabs
      title="Mes personnes de confiance : "
      headerColor="danger"
      tabs={[
        {
          tabName: "Meriem",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0, 3]}
            sendSettingIndexes={[0, 1, 2, 3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Meriem",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0, 3]}
            sendSettingIndexes={[0, 1, 2, 3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Meriem",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0, 3]}
            sendSettingIndexes={[0, 1, 2, 3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Meriem",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0, 3]}
            sendSettingIndexes={[0, 1, 2, 3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Meriem",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0, 3]}
            sendSettingIndexes={[0, 1, 2, 3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Tony",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0]}
            sendSettingIndexes={[0, 1,2,3]}
            sendSetting={docs}
            />
          )
        },
        {
          tabName: "Omar",
          tabIcon: Person,
          tabContent: (
            <SendSetting
            checkedIndexes={[0,1,2]}
            sendSettingIndexes={[0, 1, 2,3]}
            sendSetting={docs}
            />
          )
        }
      ]}
      />
      </div>

{/*
    <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
                <CardHeader color="success">
                    <ChartistGraph
                    className="ct-chart"
                    data={dailySalesChart.data}
                    type="Line"
                    options={dailySalesChart.options}
                    listener={dailySalesChart.animation}
                    />
                </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>Daily Sales</h4>
                    <p className={classes.cardCategory}>
                    <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                    </span>{" "}
                    increase in today sales.
                    </p>
                </CardBody>
                <CardFooter chart>
                    <div className={classes.stats}>
                    <AccessTime /> updated 4 minutes ago
                    </div>
                </CardFooter>
            </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
                <CardHeader color="warning">
                    <ChartistGraph
                    className="ct-chart"
                    data={emailsSubscriptionChart.data}
                    type="Bar"
                    options={emailsSubscriptionChart.options}
                    responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                    listener={emailsSubscriptionChart.animation}
                    />
                </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>Email Subscriptions</h4>
                    <p className={classes.cardCategory}>Last Campaign Performance</p>
                </CardBody>
                <CardFooter chart>
                    <div className={classes.stats}>
                    <AccessTime /> campaign sent 2 days ago
                    </div>
                </CardFooter>
            </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
            <Card chart>
                <CardHeader color="danger">
                    <ChartistGraph
                    className="ct-chart"
                    data={completedTasksChart.data}
                    type="Line"
                    options={completedTasksChart.options}
                    listener={completedTasksChart.animation}
                    />
                </CardHeader>
                <CardBody>
                    <h4 className={classes.cardTitle}>Completed Tasks</h4>
                    <p className={classes.cardCategory}>Last Campaign Performance</p>
                </CardBody>
                <CardFooter chart>
                    <div className={classes.stats}>
                    <AccessTime /> campaign sent 2 days ago
                    </div>
                </CardFooter>
            </Card>
        </GridItem>
    </GridContainer>

    <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
            <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "docs",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                  checkedIndexes={[0, 3]}
                  tasksIndexes={[0, 1, 2, 3]}
                  tasks={docs}
                  />
                )
              },
              {
                tabName: "Website",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                  checkedIndexes={[0]}
                  tasksIndexes={[0, 1]}
                  tasks={website}
                  />
                )
              },
              {
                tabName: "Server",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                  checkedIndexes={[1]}
                  tasksIndexes={[0, 1, 2]}
                  tasks={server}
                  />
                )
              }
            ]}
            />
        </GridItem>
    <GridItem xs={12} sm={12} md={6}>
    <Card>
    <CardHeader color="warning">
    <h4 className={classes.cardTitleWhite}>Employees Stats</h4>
    <p className={classes.cardCategoryWhite}>
    New employees on 15th September, 2016
    </p>
    </CardHeader>
    <CardBody>
    <Table
    tableHeaderColor="warning"
    tableHead={["ID", "Name", "Salary", "Country"]}
    tableData={[
      ["1", "Dakota Rice", "$36,738", "Niger"],
      ["2", "Minerva Hooper", "$23,789", "Curaçao"],
      ["3", "Sage Rodriguez", "$56,142", "Netherlands"],
      ["4", "Philip Chaney", "$38,735", "Korea, South"]
    ]}
    />
    </CardBody>
    </Card>
    </GridItem>
    </GridContainer>*/}
    </div>
  );
}
